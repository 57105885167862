:root {
	--main-width: 90%;
	--main-max-width: 61.5rem;
	--main-color: #36464e;
	--main-variant-color: #5F7C8A;
	--main-light-color: #91AEBC;
	--highlight-color: #17FFFF;
	--background-color: #fff;
	--background-variant-color: #FAFAFB;
	--border-color: #E4EAED;
}

*, *:before, *:after {
	box-sizing: border-box;
}

html {
	height: 100%;
	font-family: 'IBM Plex Sans',
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	font-size: 100%;
	line-height: 1.5;
}

body {
	min-height: 100%;
	margin: 0;
	color: var(--main-color);
}

button, input, textarea {
	font: inherit;
}

p {
	margin: 0 0 1.5rem;
}

input::placeholder,
textarea::placeholder {
	color: var(--main-light-color);
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
}

.header-inner {
	width: var(--main-width);
	max-width: var(--main-max-width);
	height: 80px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-cta-link {
	display: block;
	color: inherit;
	text-decoration: none;
	padding: .5em 1em;
	background-color: var(--highlight-color);
	font-weight: 500;
}


.section-inner {
	width: var(--main-width);
	max-width: var(--main-max-width);
	margin: 0 auto;
}

.hero-inner {
	position: relative;
	padding: 9rem 0;
}

.hero-cta {
	max-width: 42rem;
}

.hero-title {
	font-size: 1.5em;
	font-weight: 300;
	line-height: 1.3;
	margin: 0 0 1.5rem;
}

.hero-cta-button {
	display: inline-block;
	color: inherit;
	text-decoration: none;
	padding: .5em 1em;
	background-color: var(--highlight-color);
	font-weight: 500;
	margin: 0;
}

.hero-cta-icon {
	display: inline-block;
	margin-left: 1em;
}

.hero-illustration {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.hero-illustration-image {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 1;
	transition: opacity .5s cubic-bezier(0.4, 0, 1, 1);
}

.hero-illustration-image__display {
	opacity: 0;
}

@media (max-width: 62em) {
	.hero-inner {
		padding-top: 3rem;
		padding-bottom: calc(310px + 1.5rem);
	}
}


@media (max-width: 28em) {
	.hero-title {
		font-size: 1.6em;
	}
}

.services {
	background-color: var(--background-variant-color);
	padding: 3rem 0;
}

.services-title {
	width: var(--main-width);
	max-width: var(--main-max-width);
	margin: 0 auto 1.5rem;
	font-size: 1.5em;
	line-height: 1.3;
}

.services-list {
	display: grid;
	grid-template-columns: repeat(3, 1.5rem 30rem) 1.5rem;

	list-style: none;
	padding: 0;
	overflow-x: auto;
    margin: 0 auto;
	max-width: calc((30rem * 3) + (1.5rem * 4));
}

.service-list-sep {
	width: 1.5rem;
}

.service {
	background-color: var(--background-color);
	border: 1px solid var(--border-color);
	padding: 2rem;
	font-size: 0.875em;
	line-height: 1.42;
	width: 30rem;
	flex: 0 0 auto;
	display: flex;
}

.service:last-child {
	margin-right: 0;
}

.service-icon {
	margin-right: 2rem;
}

.service-icon-image {
	margin-bottom: 1rem;
}

.service-title {
	font-size: 1.125em;
	margin: 0 0 1rem;
	line-height: 1.3;
}

.service-details {
	list-style: none;
	margin-left: 0;
	padding: 0;
	font-weight: 500;
}

.service-details-item {
	display: inline;
}

.service-details-item:first-child:before {
	content: '';
}

.service-details-item:before {
	content: ' / ';
	color: var(--highlight-color);
}

@media (max-width: 35em) {
	.services-list {
		grid-template-columns: repeat(3, 1.5rem 17.5rem) 1.5rem;
	}

	.service {
		max-width: 17.5rem;
		display: block;
	}
}


.contact {
	padding: 3rem 0;
}

.contact-inner {
	width: var(--main-width);
	max-width: var(--main-max-width);
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(2, calc(50% - .75rem));
	grid-gap: 1.5rem;
}

.contact-title {
	font-size: 1.5em;
	line-height: 1.3;
	margin-bottom: 1.5rem;
}

.contact-description {
	margin-bottom: 1.5rem;
}

.contact-info {
	list-style: none;
	padding: 0;
	margin: 0 0 3rem;
}

.contact-info-item {
	margin-bottom: 1.5rem;
}

.contact-info-item-link {
	color: inherit;
	text-decoration: none;
	font-weight: 500;
	border-bottom: 2px solid var(--highlight-color);
	padding-bottom: 2px;
}

@media (max-width: 62em) {
	.contact-inner {
		display: block;
	}

	.contact-copy {
		max-width: 30rem;
		margin: 0 auto 3rem;
	}
}

.form-group {
	margin-bottom: 1.5rem;
}

.form-label {
	display: block;
	font-size: .75em;
	margin-bottom: .5rem;
}

.form-input,
.form-textarea {
	width: 100%;
	background-color: #F6F7F8;
	padding: 1em;
	border: 0;
	border-bottom: 1px solid var(--main-variant-color);
}

.form-input:focus,
.form-textarea:focus {
	outline: var(--highlight-color) solid 2px;
}

.form-textarea {
	resize: vertical;
}

.form-button {
	padding: .5em 1em;
	background-color: var(--highlight-color);
	margin: 0 auto 3rem;
	border: 0;
	font-weight: 500;
}

@media (max-width: 62em) {
	.form {
		max-width: 30rem;
		margin: 0 auto;
	}
}

.footer {
	width: var(--main-width);
	max-width: var(--main-max-width);
	margin: 0 auto;
	padding: 1.5rem 0;
}

.footer-copyright {
	font-size: .75em;
	color: var(--main-color);
	margin: 0;
}
